<template>
  <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ $route.name }}</h4>
        <el-col :md="18">
          <el-row :gutter="10" type="flex" justify="end">
            <el-dropdown
              @command="(c) => filterPublicSells(c)"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Exibir por: {{ displayPage || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="Ativas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Ativas',
                      }"
                    >
                      Ativas
                    </li></el-dropdown-item
                  >
                  <el-dropdown-item command="Canceladas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Canceladas',
                      }"
                    >
                      Canceladas
                    </li></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              type="primary"
              :style="{ 'margin-left': '10px' }"
              icon="el-icon-plus"
              @click="$router.push('/licitacoes/novo')"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :data="PublicSells"
      style="width: 100%; z-index: 0"
      :header-style="
        () => 'text-align:center;padding-left: 2px;padding-right:2px;'
      "
      :cell-style="
        () => 'text-align:center;padding-left: 2px;padding-right:2px;'
      "
    >
      <el-table-column label="nome" prop="name">
        <template #default="{ row: publicSell }">
          <label>{{ publicSell.name }}</label>
          <br />
          <span class="company-name">
            {{ publicSell.firm?.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="data inicio"
        prop="start_at"
        :formatter="({ start_at }) => dateFormatter.format(start_at)"
      >
      </el-table-column>
      <el-table-column
        label="data final"
        prop="end_at"
        :formatter="({ end_at }) => dateFormatter.format(end_at)"
      >
      </el-table-column>
      <el-table-column
        label="total de itens"
        prop="products.length"
      ></el-table-column>
      <el-table-column
        label="% vendida"
        prop="total_sold"
        :formatter="(publicSell) => calcTotalSold(publicSell)"
      >
      </el-table-column>
      <el-table-column label="ações" min-width="80px">
        <template #default="{ row: publicSell }">
          <el-button-group>
            <el-button
              onlyIcon
              title="Editar produto"
              type="primary"
              icon="el-icon-position"
              @click="$router.push(`/licitacoes/${publicSell.uid}`)"
            >
            </el-button>
            <el-popconfirm
              @confirm="deleteProductSell(publicSell)"
              v-if="Can('DeletePublicSell')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button onlyIcon type="danger" title="Remover licitação">
                  <i class="el-icon-delete"></i></el-button></template
            ></el-popconfirm> </el-button-group
        ></template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="pagination = $event"
      :current-page="pagination"
      background
      layout="prev, pager, next"
      :page-count="publicSells?.lastPage"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from "../utils/formatters";
import PublicSellService from "../services/public_sells";
export default {
  name: "PublicSellsPage",
  data() {
    return {
      publicSells: null,
      displayPage: "Ativas",
      pagination: 1,
      isLoading: false,
      filterOptions: {},
      currencyFormatter,
      dateFormatter,
      numberFormatter,
    };
  },
  computed: {
    PublicSells() {
      return this.publicSells?.data || [];
    },
    Can() {
      return this.$store.state.auth.can;
    },
  },
  mounted() {
    this.fetchPublicSells();
  },
  watch: {
    pagination() {
      this.fetchPublicSells();
    },
  },
  methods: {
    filterPublicSells(filterBy) {
      this.displayPage = filterBy;
      const filterByStatus = filterBy === "Ativas" ? "active" : "inactive";

      this.filterOptions["status"] = filterByStatus;
      this.pagination = 1;

      this.fetchPublicSells();
    },
    toggleLoading(loading) {
      this.isLoading = loading || !this.isLoading;
    },
    calcTotalSold(publicSell) {
      if (publicSell?.products) {
        const maxWeight =
          publicSell.products.reduce(
            (total, { total_weight }) => total + Number(total_weight),
            0
          ) || 0;
        const soldWeight =
          publicSell?.orders?.reduce(
            (total, order) =>
              total +
              order?.products?.reduce(
                (total, product) => total + Number(product.weight),
                0
              ),
            0
          ) || 0;
        return `${numberFormatter.format((soldWeight / maxWeight) * 100)} %`;
      }

      return "0 %";
    },
    async fetchPublicSells() {
      this.toggleLoading(true);

      const { publicSells } = await PublicSellService().index({
        ...this.filterOptions,
        pagination: this.pagination,
      });

      if (publicSells) this.publicSells = publicSells;

      this.toggleLoading(false);
    },
  },
};
</script>
<style scoped>
.company-name {
  font-size: 0.5rem;
  font-weight: 600;
  color: grey;
}
</style>
